<template>
    <div>
        <div class="main-content rovlin-prod-content-dashboard">
            <div class="main-content-container">
                <div class="d-flex justify-content-between pb-3 pt-4">
                    <h3 class="rovlin-prod-page-title" style="text-align: left">
                        Dashboard
                    </h3>
                    <div class="dropdown">
                        <button class="btn btn-primary rounded-4 dropdown-toggle" type="button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {{ statsFilter.type }}
                        </button>
                        <ul class="dropdown-menu py-0 rounded-md" aria-labelledby="dropdownMenuButton1 ">
                            <li v-for="(item, index) in statusFilterConstant" :key="index"
                                @click="setStatsFilter(item)"><a :class="{ 'btn-primary': statsFilter.type == item }"
                                    class="btn dropdown-item rounded-0">{{ item }}</a></li>
                        </ul>
                    </div>
                </div>

                <!-- -- charts ---- -->
                <div class="row pb-3" style="z-index: 0 !important;">
                    <div class="col-sm-12 col-lg-6 col-xl-3 my-3">
                        <div class="card  custom-new-card card-small h-100">
                            <div class="card-body">
                                <!-- shimmer loading effect -->
                                <div v-if="isLoading" class="d-flex justify-content-center">
                                    <div>
                                        <free-style-shimmer :is-loading="true" height="180px" width="180px"
                                            border-radius="90px" color="#bdbdbd" />
                                        <paragraph-shimmer class="mt-2" :is-loading="true" :lines="2"
                                            :random-size="true" />
                                    </div>
                                </div>

                                <!---- Pie Chart Campaign--->
                                <highCharts v-else :options="pieChartCampaigns"></highCharts>
                                <i v-if="!hasChartData" class="bi bi-pie-chart-fill rovlin-prod-chart-icon"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-3 my-3">
                        <div class="card card-small  custom-new-card h-80">
                            <div class="card-body">
                                <!-- shimmer loading effect -->
                                <div v-if="isLoading" class="d-flex justify-content-center">
                                    <div>
                                        <free-style-shimmer :is-loading="true" height="180px" width="180px"
                                            border-radius="90px" color="#bdbdbd" />
                                        <paragraph-shimmer class="mt-2" :is-loading="true" :lines="2"
                                            :random-size="true" />
                                    </div>
                                </div>

                                <!---- Pie Chart Spam--->
                                <highCharts v-else :options="pieChartSpam"></highCharts>
                                <i v-if="!hasSpamData" class="bi bi-pie-chart-fill rovlin-prod-chart-icon"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-3 my-3">
                        <div class="card card-small  custom-new-card h-80">
                            <div class="card-body">
                                <!-- shimmer loading effect -->
                                <div v-if="isLoading" class="d-flex justify-content-center">
                                    <div>
                                        <free-style-shimmer :is-loading="true" height="180px" width="180px"
                                            border-radius="90px" color="#bdbdbd" />
                                        <paragraph-shimmer class="mt-2" :is-loading="true" :lines="2"
                                            :random-size="true" />
                                    </div>
                                </div>

                                <!---- Pie Chart Bounce--->
                                <highCharts v-else :options="pieChartBounce"></highCharts>
                                <i v-if="!hasBounceData" class="bi bi-pie-chart-fill rovlin-prod-chart-icon"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-lg-6 col-xl-3 my-3">
                        <div class="card card-small custom-new-card h-100">
                            <div class="card-body d-flex justify-content-center align-items-center flex-wrap">
                                <button data-bs-toggle="modal" data-bs-target="#newModal" class="d-none"
                                    ref="runModal"></button>
                                <button type="button"
                                    class="w-100 border-0 d-flex pe-5 justify-content-evenly align-items-center rounded-2 stats-card bg-primary-transparent my-1"
                                    @click="openStats('recentCampaign')">
                                    <span class="bi bi-bar-chart" style="font-size: 25px;"></span> Recent Campaign
                                </button>
                                <button type="button"
                                    class="w-100 border-0 d-flex justify-content-evenly align-items-center rounded-2 stats-card  bg-success-transparent my-1 "
                                    @click="openStats('mostPopularCampaign')">
                                    <span class="bi bi-graph-up-arrow" style="font-size: 25px;"></span> Most
                                    Popular Campaign
                                </button>
                                <button type="button"
                                    class="w-100 border-0 d-flex justify-content-evenly align-items-center rounded-2 stats-card  bg-danger-transparent my-1 "
                                    @click="openStats('leastPopularCampaign')">
                                    <span class="bi bi-graph-down-arrow" style="font-size: 25px;"></span>Least
                                    Popular Campaign
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="newModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg rovlin-prod-stats-modal-dialog">
                            <div class="modal-content" v-if="showStats">
                                <div class="modal-header">
                                    <div class="modal-title">
                                        <strong>{{
                                            campaignStatsDetails.name
                                            }}
                                            Stats</strong>
                                    </div>
                                    <span>
                                        <button class=" btn rounded-4 btn-primary mx-1" @click="modalSwitch()">
                                            <span class="bi bi-bar-chart-fill mx-1">
                                            </span>
                                            {{ modalBtnText }}
                                        </button>
                                        <button type="button" class="btn-close m-0 pt-0" data-bs-dismiss="modal"
                                            aria-label="Close" @click="closeStats()"></button>
                                    </span>
                                </div>
                                <div class="modal-body">
                                    <CampaignStats v-if="!showStatsGraph"
                                        :campaignStatsDetails="campaignStatsDetails" />
                                    <CampaignStatsGraph v-else :campaignStatsDetails="campaignStatsDetails" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-6 my-3">
                        <!-- shimmer loading effect -->
                        <div v-if="isLoading" class="card custom-new-card card-small h-100">
                            <div class="card-body">
                                <free-style-shimmer :is-loading="true" class="h-100" height="300px" width="100%"
                                    border-radius="20px" color="#bdbdbd" />
                            </div>
                        </div>

                        <!---- Funnel Chart--->
                        <div v-else class="card custom-new-card card-small h-100">
                            <div v-if="funnelHasData" class="card-body">
                                <highCharts :options="funnelChart"></highCharts>
                            </div>
                            <div v-else class="card-body">
                                <p class="highcharts-title text-center" data-z-index="4"
                                    style="color: rgb(51, 51, 51); font-size: 15px; fill: rgb(51, 51, 51);" y="24">
                                    Funnel
                                    Graph</p>
                                <div class="d-flex h-75 align-items-center">
                                    <i class="bi bi-funnel-fill rovlin-prod-chart-icon"></i>
                                    <p class="flex-grow-1 text-center rovlin-prod-no-funnel-data">No data available</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 my-3">
                        <!-- shimmer loading effect -->
                        <div v-if="isLoading" class="card custom-new-card card-small h-100">
                            <div class="card-body">
                                <free-style-shimmer :is-loading="true" class="h-100" height="300px" width="100%"
                                    border-radius="20px" color="#bdbdbd" />
                            </div>
                        </div>

                        <!---- Funnel Chart--->
                        <div v-else class="card custom-new-card stats-small stats-small--1 card card-small h-100">
                            <div class="card-body">
                                <highCharts :options="lineCampaigns"></highCharts>
                                <i v-if="!hasLineChartData"
                                    class="bi bi-bar-chart-line-fill rovlin-prod-chart-icon"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 py-3">
                        <div class="card custom-new-card card-small h-100">
                            <div class="card-header rovlin-prod-headerColor border-bottom">
                                <h6 class="m-0">Email Statistics</h6>
                            </div>
                            <div class="card-body p-0">
                                <div class="list-group list-group-flush" v-if="emailStatistics">
                                    <div class="p-3 list-group-item">
                                        <span class="d-flex mb-2">
                                            <strong class="mr-1 rovlin-prod-email-title">Recently Campaign
                                                Opened</strong>:
                                            {{
                                            this.dashboardData
                                            .Email_Statistics &&
                                            this.dashboardData.Email_Statistics
                                            .Latest_Campaign_Opened_Email
                                            }}
                                        </span>
                                        <span class="d-flex mb-2">
                                            <strong class="mr-1 rovlin-prod-email-title">Recently Campaign
                                                Sent</strong>:
                                            {{
                                            this.dashboardData
                                            .Email_Statistics &&
                                            this.dashboardData.Email_Statistics
                                            .Latest_Campaign_Sent_Email
                                            }}
                                        </span>
                                        <span class="d-flex mb-2">
                                            <strong class="mr-1 rovlin-prod-email-title">Bounce Rate</strong>:
                                            <span class="mr-1 rovlin-prod-email-title"> {{
                                                this.dashboardData.Bounce_Rate.toFixed(2) }}</span>
                                        </span>
                                        <span class="d-flex mb-2">
                                            <strong class="mr-1 rovlin-prod-email-title">Spam Rate</strong>:
                                            <span class="mr-1 rovlin-prod-email-title"> {{
                                                this.dashboardData.Spam_Rate.toFixed(2) }}</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="p-3 text-center" v-else>
                                    <p>No Email Statistics</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- -- list of templates --  -->
                    <!-- <div class="col-sm-12 col-md-6 py-3">
                        <div class="row h-100">
                            <div class="col-sm-12 col-md-6 rovlin-prod-template-mobile">
                                <div class="card custom-new-card card-small h-100">
                                    <div class="card-header rovlin-prod-headerColor border-bottom">
                                        <h6 class="m-0">Latest Templates</h6>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="list-group list-group-flush">
                                            <div class="p-3 list-group-item" v-if="latestTemplate.length">
                                                <span class="d-flex mb-2">
                                                    <strong class="mr-1 rovlin-prod-template-title">Name</strong>:
                                                    {{ this.latestTemplate.name }}
                                                </span>
                                                <span class="d-flex mb-2">
                                                    <strong class="mr-1 rovlin-prod-template-title">Tag</strong>:
                                                    {{ this.latestTemplate.tag }}
                                                </span>
                                                <span class="mb-2">
                                                    <strong class="mr-1 rovlin-prod-template-desc">Description</strong>:
                                                    {{
                                                        this.latestTemplate
                                                            .Description
                                                    }}
                                                </span>
                                            </div>
                                            <div class="p-3 text-center" v-else>
                                                <p>No Latest Templates</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pb-3 pe-3">
                                        <div class="row"> -->
                    <!-- View Full Report -->
                    <!-- <div class="view-report d-flex justify-content-end">
                                                <router-link :to="{ name: 'createTemplate' }"
                                                    class="text-muted navigate-link">View all Templates
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                    <!--Show Available balance-->
                    <!-- <div class="col-sm-12 col-md-6 rovlin-prod-balance-mobile">
                                <div class="card custom-new-card card-small h-100">
                                    <div class="card-header rovlin-prod-headerColor border-bottom">
                                        <h6 class="m-0">Available Balance</h6>
                                    </div>
                                    <div class="card-body p-0">
                                        <div class="d-flex justify-content-center align-items-center h-100"
                                            v-if="this.$store.state.balance > 0">
                                            <span class="fa-3x" style="font-size: 3rem">{{ this.$store.state.balance
                                            }}</span>
                                            <sub class="px-1 fa">Left</sub>
                                        </div>
                                        <div class="p-3 text-center" v-else>
                                            <span>No Available Balance</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- Latest campaigns table -->
                    <!-- <div class="col-sm-12 col-md-6 my-3">
                        <div class="card custom-new-card card-small h-100">
                            <div class="card-header border-bottom rovlin-prod-headerColor">
                                <h6 class="m-0">Latest Campaigns</h6>
                            </div>
                            <div class="card-body p-0">
                                <div class="container-fluid px-0" v-if="latestCamp.length"> -->
                    <!-- Latest Campaigns List -->
                    <!-- <table class="table mb-0">
                                        <thead class="py-2 bg-light text-semibold border-bottom">
                                            <tr>
                                                <th>Name</th>
                                                <th class="">start date</th>
                                                <th class="">End date</th>
                                                <th class="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, idx) in this.latestCamp" :key="idx">
                                                <td class="lo-stats__order-details">
                                                    <span>{{ item.Name }}</span>
                                                </td>
                                                <td class="lo-stats__order-details">
                                                    <span>{{ item.startDate }}</span>
                                                </td>
                                                <td class="lo-stats__order-details">
                                                    <span>{{ item.endDate }}</span>
                                                </td>
                                                <td class="lo-stats__status">
                                                    <div class="d-table mx-auto">
                                                        <span class="badge rounded-pill {{ getBadgeType(item.status) }}">
                                                            {{ item.status }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="p-3 text-center" v-else>
                                    <p>No Latest Campaigns</p>
                                </div>
                            </div>
                            <div class="pb-3 pe-3">
                                <div class="row"> -->
                    <!-- View Full Report -->
                    <!-- <div class="col text-end view-report">
                                        <router-link :to="{ name: 'campaigns' }" class="navigate-link">
                                            View all campaigns
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-sm-12 col-md-6 my-3">
                        <div class="card custom-new-card card-small h-100">
                            <div class="card-header border-bottom rovlin-prod-headerColor">
                                <h6 class="m-0">Account Overview</h6>
                            </div>
                            <div class="card-body p-0">
                                <div class="list-group list-group-flush">
                                    <div class="p-3">
                                        <div class="d-flex mb-2">
                                            <strong class="me-1">Date: {{ this.lastTransaction.paymentDate }}</strong>

                                        </div>
                                        <div class="row mb-2">
                                            <div class="col">
                                                <span class="">
                                                    <strong class="me-1">Total:</strong>
                                                    <span class="me-1 text-success">
                                                        ${{ this.lastTransaction.Amount }}
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="col text-end">
                                                <span class="">
                                                    <span class="badge rounded-pill bg-warning">
                                                        {{ this.lastTransaction.Status }}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-2">
                                            <strong class="me-1">Available Balance:{{ this.$store.state.balance
                                                }}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pb-3 pe-3">
                                <div class="row">
                                    <!-- View Full Report -->
                                    <div class="text-end view-report">
                                        <router-link :to="{ name: 'transaction-history' }" class="navigate-link">View
                                            all
                                            Transactions <i class="bi bi-arrow-right-short"
                                                style='font-size:24px; vertical-align:sub;'></i></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import APIService from "../../../Services/ApiService/apiService";
import campaignStats from "../campaign/CampaignStats.vue";
import CampaignGraph from "../campaign/CampaignGraph.vue";
import Highcharts from "highcharts";
import funnel from "highcharts/modules/funnel";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { ParagraphShimmer, FreeStyleShimmer } from 'vue3-shimmer'

NoDataToDisplay(Highcharts);
funnel(Highcharts);
export default {
    name: "dashboard-vue",
    components: {
        highCharts: Chart,
        CampaignStats: campaignStats,
        CampaignStatsGraph: CampaignGraph,
        FreeStyleShimmer: FreeStyleShimmer,
        ParagraphShimmer: ParagraphShimmer,
        // CardShimmer: CardShimmer,
    },
    data() {
        return {
            //
            statsFilter: {
                type: 'All Time',
                startTime: null
            },
            statusFilterConstant: [
                'All Time', "Today", "Yesterday", "Last Week", "Last Month", "Last 6 Month", "Last Year", "Last 2 Year"
            ],
            //
            dashboardData: {},
            latestCamp: "",
            latestTemplate: "",
            lastTransaction: "",
            availBalance: 0,
            numberOfCampaigns: 0,
            statusBasedCampaignsStats: {},
            activeCampaign: {},
            completedCampaign: {},
            inProgress: {},
            campaignData: {},
            campaignStatsDetails: {},
            showStats: false,
            showStatsGraph: false,
            modalBtnText: "View Stats",
            funnelHasData: false,
            hasLineChartData: true,
            hasBounceData: true,
            hasSpamData: true,
            hasChartData: true,
            isLoading: true,

            //linechart campaigns
            lineCampaigns: {
                title: {
                    text: 'Your Campaigns States',  // Plain text
                    margin: 8,
                    style: {
                        fontSize: '15px',
                        fontWeight: '500',
                    }
                },

                credits: {
                    enabled: false
                },

                subtitle: {
                    text: "",
                },

                chart: {
                    //  height: (9 / 16 * 100) + '%' // 16:9 ratio
                    height: "350px", // 16:9 ratio
                },

                yAxis: {
                    visible: !this.hasLineChartData,
                    title: {
                        text: "Number of Campaigns",
                    },
                },

                xAxis: {
                    accessibility: {
                        rangeDescription: "Range: Week-1 to Week-4",
                    },
                    categories: ["Week-1", "Week-2", "Week-3", "Week-4"],
                    lineWidth: this.hasLineChartData ? 1 : 0,
                },

                legend: {
                    layout: "vertical",
                    align: "center",
                    verticalAlign: "top",
                    enabled: !this.hasLineChartData,
                    itemStyle: {
                        fontWeight: '500',
                    }

                },

                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false,
                        },
                    },
                },
                series: [{
                    name: "Number of Active Campaigns",
                    data: []
                }],
                lang: {
                    noData: 'No Campaigns States Data'
                },
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 200,
                            },
                            chartOptions: {
                                legend: {
                                    layout: "horizontal",
                                    align: "center",
                                    verticalAlign: "bottom",
                                },
                            },
                        },
                    ],
                },
            },
            //pieChart for active campaigns
            pieChartCampaigns: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                    height: "250px",
                    style: {
                        // opacity: 0.5
                    }
                },
                title: {
                    text: `Campaigns`,
                    useHTML: true, // This allows the use of HTML in the title
                    style: {
                        fontWeight: '500',  // Set font weight
                        fontSize: '15px',   // Optional: Set font size
                    }
                },
                credits: {
                    enabled: false // This will remove the HighCharts.com text
                },
                tooltip: {
                    pointFormat:
                        "{series.name}: <b>{point.percentage:.1f}%</b>",
                },
                accessibility: {
                    point: {
                        valueSuffix: "%",
                    },
                },
                plotOptions: {
                    pie: {
                        colors: [
                            'rgba(255, 0, 0, 0.5)',  // Red color with 50% opacity
                            'rgba(0, 255, 0, 0.5)',  // Green color with 50% opacity
                            'rgba(0, 0, 255, 0.5)',  // Blue color with 50% opacity
                        ],
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: true,
                            style: {
                                fontWeight: '500',
                            },
                        },
                        showInLegend: true,
                        borderWidth: 0
                    },
                },
                series: [
                    {
                        name: "Camapigns",
                        colorByPoint: true,
                        data: [
                            {
                                name: "Active",
                                y: null,
                                sliced: true,
                                selected: true,
                                color: "#0c244a",
                            },
                            {
                                name: "In Progress",
                                y: null,
                                color: "#B0C4DE",
                            },
                            {
                                name: "Completed",
                                y: null,
                                color: "#8A8AFF",
                            },
                        ],
                    },
                ],
                legend: {
                    itemStyle: {
                        fontWeight: '500',
                    }
                },

                lang: {
                    noData: 'No Active Campaigns Data'
                }
            },

            //pieChart for bounce_rate
            pieChartBounce: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                    height: "250px",
                },
                title: {
                    text: `Bounce Rate`,
                    useHTML: true, // This allows the use of HTML in the title
                    style: {
                        fontWeight: '500',  // Set font weight
                        fontSize: '15px',   // Optional: Set font size
                    }
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    pointFormat:
                        "{series.name}: <b>{point.percentage:.1f}%</b>",
                },
                accessibility: {
                    point: {
                        valueSuffix: "%",
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: false,
                            style: {
                                fontWeight:'500',
                            }
                        },
                        showInLegend: true,
                        borderWidth: 0
                    },
                },
                series: [
                    {
                        name: "Bounce",
                        colorByPoint: true,
                        data: [
                            {
                                name: "Bounce Rate",
                                y: null,
                                sliced: true,
                                selected: true,
                                color: "#0c244a",
                            },
                            {
                                name: "Active Rate",
                                y: null,
                                color: "#B0C4DE",
                            },
                        ],
                    },
                ],
                legend: {
                    itemStyle: {
                        fontWeight: '500',
                    }
                },

                lang: {
                    noData: 'No Bounce Rate Data'
                }
            },

            //piechart for spam_rate
            pieChartSpam: {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: "pie",
                    height: "250px",
                },
                title: {
                    text: `Spam Rate`,
                    useHTML: true, // This allows the use of HTML in the title
                    style: {
                        fontWeight: '500',  // Set font weight
                        fontSize: '15px',   // Optional: Set font size
                    }
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    pointFormat:
                        "{series.name}: <b>{point.percentage:.1f}%</b>",
                },
                accessibility: {
                    point: {
                        valueSuffix: "%",
                    },
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        dataLabels: {
                            enabled: false,
                            style: {
                                fontWeight:'500'
                            }
                        },
                        showInLegend: true,
                        borderWidth: 0
                    },
                },
                series: [
                    {
                        name: "Spam",
                        colorByPoint: true,
                        data: [
                            {
                                name: "Spam Rate",
                                y: null,
                                sliced: true,
                                selected: true,
                                color: "#0c244a",
                            },
                            {
                                name: "Active Rate",
                                y: null,
                                color: "#B0C4DE",
                            },
                        ],
                    },
                ],
                legend: {
                    itemStyle: {
                        fontWeight: '500',
                    }
                },
                lang: {
                    noData: 'No Spam Rate Data'
                }
            },
            funnelChart: this.getFunnelChartConfig([]),
        };
    },
    computed: {
        emailStatistics() {
            const hasEmailOpened = this.dashboardData.Email_Statistics && this.dashboardData.Email_Statistics.Latest_Campaign_Opened_Email;
            const hasEmailSent = this.dashboardData.Email_Statistics && this.dashboardData.Email_Statistics.Latest_Campaign_Sent_Email;
            return hasEmailOpened || hasEmailSent || this.dashboardData.Bounce_Rate || this.dashboardData.Spam_Rate;
        },
    },
    created() {
        if (!this.$store.state.JwtToken) {
            this.$store.dispatch('fetchJwtToken')
        }
        this.getDashBoard();
    },
    methods: {
        setStatsFilter(item) {
            console.log("Filter Val : stage 2", item)
            if (this.statsFilter.type == item) return
            this.statsFilter['type'] = item
            if (item == 'All Time') {
                this.getDashBoard();
                return
            }
            else {
                this.statsFilter['startTime'] = this.getStartTimeISOString(this.statsFilter.type);
                if (this.statsFilter['startTime']) this.getDashBoard(this.statsFilter);
                return
            }
        },
        getBadgeType(status) {
            const statusMap = {
                ACTIVE: "success",
                Pending: "warning",
                Canceled: "danger",
            };

            return `badge-${statusMap[status]}`;
        },

        getDashBoard(filterVal = null) {
            console.log("Filter Val : stage 1", filterVal)
            // this.$store.state.isLoading = true;
            APIService.getDashboard(filterVal)
                .then((val) => {
                    this.dashboardData = val.data.Email_Statistics;
                    this.lastTransaction = val.data.Last_Transaction;
                    this.latestTemplate = val.data.Latest_template;
                    this.statusBasedCampaignsStats = val.data.Status_based_campaigns_stats;
                    if (this.dashboardData.Bounce_Rate) {
                        const newBounceRate = [
                            {
                                name: "Bounce Rate",
                                y: null,
                                sliced: true,
                                selected: true,
                                color: "#0c244a",
                            },
                            {
                                name: "Active Rate",
                                y: null,
                                color: "#B0C4DE",
                            },
                        ]
                        newBounceRate[0].y = this.dashboardData.Bounce_Rate;
                        newBounceRate[1].y = 100 - this.dashboardData.Bounce_Rate;
                        this.pieChartBounce.series[0].data = newBounceRate
                        this.hasBounceData = true;
                    } else {
                        this.pieChartBounce.series[0].data = [];
                        this.hasBounceData = false;
                    }
                    if (this.dashboardData.Spam_Rate) {
                        const newSpanRate = [
                            {
                                name: "Spam Rate",
                                y: null,
                                sliced: true,
                                selected: true,
                                color: "#0c244a",
                            },
                            {
                                name: "Active Rate",
                                y: null,
                                color: "#B0C4DE",
                            },
                        ]
                        newSpanRate[0].y = this.dashboardData.Spam_Rate;
                        newSpanRate[1].y = 100 - this.dashboardData.Spam_Rate;
                        this.pieChartSpam.series[0].data = newSpanRate
                        this.hasSpamData = true;
                    } else {
                        this.pieChartSpam.series[0].data = [];
                        this.hasSpamData = false;
                    }
                    if (val.data.Number_of_active_campaigns_week_wise) {
                        const allZero = val.data.Number_of_active_campaigns_week_wise.every(point => point === 0);

                        if (allZero) {
                            this.hasLineChartData = false;
                            this.lineCampaigns.series[0].data = [];
                        } else {
                            this.hasLineChartData = true;
                            this.lineCampaigns.series[0].data = val.data.Number_of_active_campaigns_week_wise;
                        }
                    } else {
                        this.hasLineChartData = false;
                        this.lineCampaigns.series[0].data = [];
                    }
                    console.log("lineCampaigns", this.lineCampaigns.series[0].data);
                    this.numberOfCampaigns = val.data.Number_of_campaigns;
                    this.campaignData.recentCampaign = val.data.recentCampaign;
                    this.campaignData.mostPopularCampaign = val.data.mostPopularCampaign;
                    this.campaignData.leastPopularCampaign = val.data.leastPopularCampaign;
                    this.funnelChart = this.getFunnelChartConfig(
                        val.data.funnelGraphData
                    );
                    this.funnelHasData = val.data.funnelGraphData.delivered || val.data.funnelGraphData.unique_opens || val.data.funnelGraphData.unique_clicks || val.data.funnelGraphData.email_unsubscribed
                    if (this.numberOfCampaigns) {
                        this.activeCampaign = (this.statusBasedCampaignsStats.active / this.numberOfCampaigns) * 100;
                        this.completedCampaign = (this.statusBasedCampaignsStats.completed / this.numberOfCampaigns) * 100;
                        this.inProgressCampaign = (this.statusBasedCampaignsStats.inProgress / this.numberOfCampaigns) * 100;
                        const newActiveCampaign = [
                            {
                                name: "Active",
                                y: null,
                                sliced: true,
                                selected: true,
                                color: "#0c244a",
                            },
                            {
                                name: "In Progress",
                                y: null,
                                color: "#B0C4DE",
                            },
                            {
                                name: "Completed",
                                y: null,
                                color: "#8A8AFF",
                            },
                        ]
                        newActiveCampaign[0].y = this.activeCampaign;
                        newActiveCampaign[1].y = this.inProgressCampaign;
                        newActiveCampaign[2].y = this.completedCampaign;
                        this.pieChartCampaigns.series[0].data = newActiveCampaign
                        this.hasChartData = true;
                    } else {
                        this.pieChartCampaigns.series[0].data = [];
                        this.hasChartData = false;
                    }
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    // this.$store.state.isLoading = false;
                    this.isLoading = false;
                });
        },

        getBalanceUser() {
            // this.$store.state.isLoading = true;
            APIService.getBalanceUser()
                .then((res) => {
                    this.availBalance = res.data.balance;
                    console.log(this.availBalance);
                })
                .finally(() => {
                    this.$store.state.toastMessage = "";
                    // this.$store.state.isLoading = false;
                    this.isLoading = false;
                });
        },
        formatFunnelData(data) {
            console.log("funnel", data);
            return [
                ["Email Delivered", data.delivered],
                ["Unique Opens", data.unique_opens],
                ["Unique Subscriber Clicks", 60],
                ["Email Unsubscribed", 11],
            ];
        },
        closeStats() {
            this.showStats = false;
            this.campaignStatsDetails = null;
        },
        openStats(campaignCategory) {
            this.campaignStatsDetails = this.campaignData[campaignCategory];
            if (this.campaignStatsDetails) {
                this.showStats = true;
                this.$refs.runModal.click();
                this.showStatsGraph = false;
                this.modalBtnText = "View Stats";
            }
            else {
                this.$store.state.toastMessage = `${campaignCategory} Data Not Available`
            }
        },
        modalSwitch() {
            this.showStatsGraph = !this.showStatsGraph;
            if (this.showStatsGraph) {
                this.modalBtnText = "View Details";
            } else {
                this.modalBtnText = "View Stats";
            }
        },
        getFunnelChartConfig(data) {
            const funnelChart = {
                chart: {
                    type: "funnel",
                },
                title: {
                    text: `Funnel Graph`,
                    useHTML: true, // This allows the use of HTML in the title
                    style: {
                        fontWeight: '500',
                        fontSize: '15px',
                    }
                },
                credits: {
                    enabled: false // This will remove the Highcharts.com text
                },
                plotOptions: {
                    series: {
                        stacking: "normal",
                        dataLabels: {
                        enabled: true,
                        style: {
                            fontWeight: '500',
                        },
                    },
                    },
                },
                series: [
                    {
                        name: "Funnel Data",
                        data: [
                            ["Email Delivered", data.delivered],
                            ["Unique Opens", data.unique_opens],
                            ["Unique Subscriber Clicks", data.unique_clicks],
                            ["Email Unsubscribed", data.email_unsubscribed],
                        ],
                    },
                ],
            };
            return funnelChart;
        },
        getStartTimeISOString(interval) {
            const now = new Date();

            switch (interval) {
                case 'Yesterday':
                    now.setDate(now.getDate() - 1);
                    break;
                case 'Today':
                    // Start of the current day
                    now.setHours(0, 0, 0, 0);
                    break;
                case 'Last Week':
                    now.setDate(now.getDate() - 7);
                    break;
                case 'Last Month':
                    now.setMonth(now.getMonth() - 1);
                    break;
                case 'Last 6 Month':
                    now.setMonth(now.getMonth() - 6);
                    break;
                case 'Last Year':
                    now.setFullYear(now.getFullYear() - 1);
                    break;
                case 'Last 2 Year':
                    now.setFullYear(now.getFullYear() - 2);
                    break;
                default:
                    // Handle unsupported interval here
                    return '';
            }

            return now.toISOString();
        }
    }
};
</script>

<style scoped>
text.highcharts-credits {
    display: none;
}

.rovlin-prod-headerColor {
    background: none !important;
}

.rovlin-prod-headerColor h6 {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #333333;
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica,
        sans-serif;
}

.active-campaign {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: 500;
}

.rovlin-prod-content-dashboard {
    margin: 0 30px;
    min-height: calc(100vh - 113px) !important;
}

.highcharts-figure {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.btn-outline-primary {
    min-height: 45px;
    text-align: left;
    overflow: hidden;
}

.rovlin-prod-stats-modal-dialog {
    min-width: 80vw !important;
}

.rovlin-prod-content-dashboard .card-body {
    min-height: 120px;
    position: relative;
}

.highcharts-credits {
    display: none;
}

.rovlin-prod-email-title {
    width: 250px;
    font-size: 14px !important;
    font-weight: 300 !important;
}

.rovlin-prod-template-title {
    width: 100px;
}

.rovlin-prod-template-desc {
    margin-right: 10px;
}

.rovlin-prod-chart-icon {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10em;
    color: rgb(0 0 0 / 4%);
}

.rovlin-prod-no-funnel-data {
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #666666;
}

.me-1 {
    font-size: 14px !important;
    font-weight: 300 !important;
}

@media (max-width: 767px) {
    .rovlin-prod-template-mobile {
        padding-bottom: 2em;
    }
}

@media (max-width: 890px) {
    .rovlin-prod-template-mobile {
        width: 100%;
        padding-bottom: 2em;
    }

    .rovlin-prod-balance-mobile {
        width: 100%;
    }
}


.navigate-link {
    font-size: 14px !important;
    color: #5a4fdc !important;
}

.navigate-link:hover {
    transform: scale(1.1);
}

.stats-card {
    height: 54px !important;
    font-size: 15px;
}

.stats-card:hover {
    transform: translateY(-2px);
}

/* ::v-deep .rovlin-prod-content-dashboard .card-body {

    position: static;
}
::v-deepelement.style {
    position: static !important;
    overflow: hidden;
    width: 214px;
    height: 250px;
    text-align: left;
    line-height: normal;
    z-index: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    touch-action: manipulation;
    outline: none;
} */
/* ::v-deep .card{
    position: relative !important;
} */
</style>
