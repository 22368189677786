<template>
    <div class="container-fluid info-border rounded-3">
        <div class="row g-0">
            <div class="col text-center d-flex p-1">
                <i class="bi bi-info-circle-fill me-2" aria-hidden="true"></i>
                <p class="m-0" style="font-size: 14px;">{{ info }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "InformationComponent",
    props: {
        info: {
            type: String,
            required: true,
        },
    },
};
</script>
<style scoped>
.info-border {
    border: 1.5px solid;
    border-color: #0073bb;
    background-color: #f1faff;
}

.bi-info-circle-fill {
    color: #0073bb;
}
</style>
