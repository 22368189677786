<template>
    <div class="modal fade show d-block" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update Ticket Status</h5>
                    <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="ticketStatus" class="form-label">Select Status</label>
                        <select id="ticketStatus" class="form-select" v-model="selectedStatus">
                            <option v-for="(status, key) in statuses" :key="key" :value="key">
                                {{ status.label }}
                            </option>
                        </select>
                    </div>
                    <div class="mt-3" v-if="selectedStatus">
                        <span :style="{ color: getStatusProperties(selectedStatus).color }">
                            Selected Status: {{ getStatusProperties(selectedStatus).label }}
                        </span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="updateTicketStatus">Update</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { statusesConfig } from "./statusesConfig";
import { getStatusProperties } from "../../../Services/utils/statusUtils";

export default {
    props: {
        ticket: {
            type: Object,
            required: true,
        },
        showModal: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            statuses: statusesConfig,
            selectedStatus: this.ticket?.status || null,
        };
    },
    methods: {
        getStatusProperties,
        closeModal() {
            this.$emit("close");
        },
        updateTicketStatus() {
            if (!this.selectedStatus) {
                this.$emit("error", "Please select a valid status.");
                return;
            }

            const updatedData = {
                id: this.ticket.id,
                status: this.selectedStatus,
            };

            this.$emit("update", updatedData);
        },
    },
};

</script>
