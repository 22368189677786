<template>
    <div class="support-container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-10">
                <div class="border-0">
                    <div class="text-black text-center">
                        <h4 class="mb-0">We're Here to Help!</h4>
                        <p class="mb-3">Tell us your issue and we'll assist you</p>
                    </div>
                    <div class="body">
                        <form @submit.prevent="onSubmit" class="needs-validation" novalidate>
                            <!-- Issue Type Selection -->
                            <div class="mb-3">
                                <label for="f3_Issue" class="form-label">Subject</label>
                                <select id="f3_Issue" v-model="form.subject" class="form-select" required>
                                    <option value="" disabled selected>-- Select Subject --</option>
                                    <option value="billing">Billing</option>
                                    <option value="technical">Technical</option>
                                    <option value="subscription">Subscription</option>
                                    <option value="general_question">General Question</option>
                                </select>
                                <div class="invalid-feedback">
                                    Please select an issue type.
                                </div>
                            </div>

                            <!-- Description Textarea -->
                            <div class="mb-3">
                                <label for="f3_Description" class="form-label">Description</label>
                                <textarea id="f3_Description" v-model="form.description" class="form-control"
                                    placeholder="Please describe your issue" rows="5" required></textarea>
                                <div class="invalid-feedback">
                                    Description is required.
                                </div>
                            </div>

                            <!-- Submit Button -->
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary px-5 py-2 rounded-pill">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- Optional Image Section -->
        <!-- <div class="row justify-content-center py-4">
            <div class="col-md-6 text-center">
                <img :src="require('../../../assets/support.png')" class="img-fluid" alt="Support Image"
                    style="max-width: 100%;" />
            </div>
        </div> -->
    </div>
</template>

<script>
import APIService from "../../../Services/ApiService/apiService";
import { Auth } from "aws-amplify";

export default {
    name: "SupportComponent",
    data() {
        return {
            user: {},
            form: {
                subject: "",
                description: "",
            },
        };
    },
    methods: {
        onSubmit() {
            // Form validation logic
            const form = this.$el.querySelector('form');
            if (!form.checkValidity()) {
                form.classList.add('was-validated');
                return;
            }

            const body = {
                email: this.user?.attributes?.email,
                subject: this.form.subject,
                description: this.form.description,
            };

            // Simulate form submission
            this.$store.state.isLoading = true;
            APIService.createSupportTicket(body)
                .then(() => {
                    this.$store.state.toastMessage = "Your query has been submitted successfully!";
                    this.resetForm();
                    this.navigateToSupport();
                })
                .catch((error) => {
                    const errorMessage = error?.response?.data?.description || "Something went wrong. Please try again.";
                    this.$store.state.toastMessage = errorMessage;
                    this.navigateToSupport();
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        resetForm() {
            this.form.subject = "";
            this.form.description = "";
            this.$el.querySelector('form').classList.remove('was-validated');
        },
        navigateToSupport() {
            if (this.$route.path === '/support') {
                this.$router.go(0);
            } else {
                this.$router.push('/support');
            }
        }
    },

    created() {
        Auth.currentUserInfo().then((user) => {
            this.user = user;
        });
    },
};
</script>

<style scoped>
.support-container {
    padding-top: 30px;
    padding-bottom: 30px;
}

.btn-primary {
    background-color: #007bff;
    border: none;
}

.form-select,
.form-control {
    border-radius: 10px;
}

.needs-validation.was-validated .form-control:invalid {
    border-color: #dc3545;
}

.needs-validation.was-validated .form-select:invalid {
    border-color: #dc3545;
}

.form-label {
    font-weight: 600;
}

img {
    max-width: 500px;
    margin-top: 20px;
}
</style>
