<template>
    <div class="container-fluid main-content-container pb-4">
        <!-- Header Section -->
        <div class="row g-0 rovlin-prod-page-header py-4">
            <div class="col col-sm-4 text-center text-sm-start mb-4 mb-sm-0">
                <h3 class="rovlin-prod-page-title">Identity</h3>
            </div>
            <div class="col col-sm-8 text-center text-sm-end">
                <button type="button" class="btn rounded-4 btn-outline-primary" @click="openModal">
                    Verify A New Email Address
                </button>
            </div>
        </div>

        <!-- Table Section -->
        <div class="rounded-3 overflow-hidden bg-white">
            <VueGoodTable class="rounded" :rows="tableData" :columns="columns" :sort-options="{ enabled: false }">
                <template #table-row="{ row, column }">
                    <span v-if="column.field == 'action'">
                        <a>
                            <span class="loader" :class="{ rotating: refreshingEmail === row.email }"
                                @click="refreshEmail(row.email)"></span>
                            &nbsp;&nbsp;
                            <span class="bi bi-trash-fill" @click="deleteEmail(row.email)"></span>
                        </a>
                    </span>
                    <span v-if="column.field == 'resend'">
                        <a v-if="row.status !== 'Success'" class="rovlin-prod-verified-badge">
                            <span class="bi bi-arrow-clockwise" :class="{ rotating: sendingEmail === row.email }"
                                @click="resendEmail(row.email, row.friendlyName)"></span>
                            Verify
                        </a>
                        <a v-if="row.status === 'Success'" class="rovlin-prod-verified-badge">
                            <span class="bi bi-check-circle"></span>
                            Verified
                        </a>
                    </span>
                </template>
            </VueGoodTable>
        </div>

        <!-- Modal -->
        <IdentityModalComponent :showModal="showModal" @close="closeModal" @refresh="getIdentityEmail"
            @error="handleError" />
    </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import APIService from "../../../Services/ApiService/apiService";
import SweetAlert from "../../../Services/sweetAlerts/SweetAlerts";
import IdentityModalComponent from "./IdentityModalComponent.vue";

export default {
    name: "IdentityComponent",
    components: {
        VueGoodTable,
        IdentityModalComponent,
    },
    data() {
        return {
            columns: [
                { label: "Action", field: "action" },
                { label: "Email Address Identities", field: "email" },
                { label: "Verification Status", field: "status" },
                { label: "Business Name", field: "friendlyName" },
                { label: "Status", field: "resend" },
            ],
            tableData: this.$store.state.identities,
            showModal: false,
            isLoading: false,
            refreshingEmail: null,
            sendingEmail: null,
        };
    },
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        getIdentityEmail() {
            this.isLoading = true;
            APIService.identityManagement()
                .then((res) => {
                    const identityWithIndex = APIService.objectItemWithIndex(res.data);
                    this.$store.state.identities = identityWithIndex;
                    this.tableData = identityWithIndex;
                })
                .catch(() => {
                    this.$store.state.toastMessage = "Something went wrong!";
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        deleteEmail(email) {
            SweetAlert.Confirmation({ title: "Are you sure you want to delete this email?" }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteEmailIdentification(email);
                }
            });
        },
        deleteEmailIdentification(email) {
            this.isLoading = true;
            this.$store.state.toastMessage = "";
            APIService.deleteEmailIdentification(email)
                .then(
                    () => {
                        this.getIdentityEmail();
                        this.$store.state.toastMessage = "Email deleted successfully";
                    },
                    (error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message || errorMessage.response.data;
                    }
                )
                .finally(() => {
                    this.isLoading = false;
                });
        },
        refreshEmail(email) {
            this.refreshingEmail = email;
            this.isLoading = true;
            this.$store.state.toastMessage = "";
            APIService.refreshEmailIdentification(email)
                .then(() => {
                    this.getIdentityEmail();
                    this.$store.state.toastMessage = "Identities Refreshed";
                })
                .catch((error) => {
                    const errorMessage = { ...error };
                    this.$store.state.toastMessage =
                        errorMessage.response.data.message || errorMessage.response.data;
                })
                .finally(() => {
                    this.refreshingEmail = null;
                    this.isLoading = false;
                });
        },
        resendEmail(email, friendlyName) {
            const body = { email, friendlyName };
            this.sendingEmail = email;
            this.isLoading = true;
            this.$store.state.toastMessage = "";
            APIService.addEmailForVerification(body)
                .then(
                    () => {
                        this.getIdentityEmail();
                        this.$store.state.toastMessage = "Resend email successfully";
                    },
                    (error) => {
                        const errorMessage = { ...error };
                        this.$store.state.toastMessage =
                            errorMessage.response.data.message || errorMessage.response.data;
                    }
                )
                .finally(() => {
                    this.isLoading = false;
                    this.sendingEmail = null;
                });
        },
        handleError(error) {
            const errorMessage = { ...error };
            this.$store.state.toastMessage =
                errorMessage.response.data.message || errorMessage.response.data;
        },
    },
    created() {
        const query = this.$router.currentRoute.query;
        if (query && query.email) {
            this.refreshEmail(query.email);
            return;
        }
        this.getIdentityEmail();
    },
};
</script>

<style scoped>
.rovlin-prod-page-title {
    font-weight: 500;
    color: #606266;
    font-size: 20px !important;
}

.rovlin-prod-verified-badge {
    background-color: #0c244a;
    color: white;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 6px;
    font-style: italic;
    font-weight: 400;
}

.vue-good-table-wrapper {
    margin-top: 20px;
}

:deep(.vgt-table th) {
    text-align: center;
    background-color: #f5f6fa;
    font-weight: bold;
    /* padding: 10px; */
    border-bottom: 2px solid #ddd;
}

:deep(.vgt-table td) {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
}

::v-deep .vgt-responsive {
    padding:1rem !important;
    background: white !important;
}

::v-deep table.vgt-table {
    border: none;
}

::v-deep .vgt-left-align {
    /* padding: 1em 1em !important; */
}

::v-deep .vgt-table.bordered th {
    border: none;
}

::v-deep .vgt-table th {
    border-bottom: 1px solid #ddd !important;
    /* border-top: 1px solid #ddd !important; */
}

::v-deep .vgt-table.bordered td {
    border: none;
}

::v-deep .vgt-table td {
    border-bottom: 1px solid #ddd !important;
}

::v-deep .vgt-wrap__footer {
    border: none !important;
    background: #fff !important;
}

::v-deep .vgt-table thead th {
    background: #fff !important;
}

.loader {
    width: 12px;
    height: 12px;
    display: inline-block;
    position: relative;
    border: 1px solid;
    border-color: #000000 #0000 #000000 #0000;
    border-radius: 50%;
    box-sizing: border-box;
}

.loader.rotating {
    animation: 1s rotate linear infinite;
}

.loader:before,
.loader:after {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    border: 2px solid transparent;
    border-bottom-color: #000000;
    transform: translate(-2px, 4px) rotate(-35deg);
}

.loader:after {
    border-color: #000000 #0000 #0000 #0000;
    transform: translate(8px, 1px) rotate(-35deg);
}

.bi-arrow-clockwise {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
}

.bi-arrow-clockwise.rotating {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
</style>
