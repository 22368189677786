export const statusesConfig = {
    Open: {
        color: "#17a2b8", // Info blue
        label: "Open",
    },
    Resolved: {
        color: "#28a745", // Success green
        label: "Resolved",
    },
    "In Progress": {
        color: "#ffc107", // Warning yellow
        label: "In Progress",
    },
    Pending: {
        color: "#6c757d", // Secondary gray
        label: "Pending",
    },
    Closed: {
        color: "#dc3545", // Danger red
        label: "Closed",
    },
};
