<template>
    <div class="container-fluid main-content-container">
        <div class="row g-0 rovlin-prod-page-header py-4">
            <div class="col col-sm-4 text-center text-sm-start mb-4 mb-sm-0">
                <h3 class="rovlin-prod-page-title">Support History</h3>
            </div>
            <div class="col col-sm-8 text-center text-sm-end">
                <input type="date" class="form-control w-auto d-inline-block me-2" v-model="startDate" :max="today" />
                <input type="date" class="form-control w-auto d-inline-block me-2" v-model="endDate" :max="today" />
                <button type="button" v-if="isFilterApplied" class="btn-cancel d-inline-block me-2"
                    @click="clearFilter">
                    <i class="bi bi-x" style="font-size: 17px;"></i>
                </button>
                <button class="btn btn-primary rounded-4" @click="openSupportModal">Create Ticket</button>
            </div>
        </div>
        <div v-if="isSupportModalOpen" class="modal fade show d-block" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Create Support Ticket</h5>
                        <button type="button" class="btn-close" aria-label="Close" @click="closeSupportModal"></button>
                    </div>
                    <div class="modal-body">
                        <Support />
                    </div>
                </div>
            </div>
        </div>
        <StatusModal v-if="isStatusModalOpen" :ticket="selectedTicket" :showModal="isStatusModalOpen"
            @close="closeStatusModal" @update="handleTicketUpdate" @error="handleError" />

        <div class="rounded-3 overflow-hidden">
            <VueGoodTable mode="remote" v-on:page-change="onPageChange" v-on:per-page-change="onPerPageChange"
                :pagination-options="{
                    enabled: true,
                    perPageDropdownEnabled: false,
                }" :sort-options="{
                    enabled: false,
                }" :totalRows="totalRecords" :columns="columns" :rows="filteredData">
                <template #table-row="{ row, column }">
                    <span v-if="column.field === 'sNo'">{{ row.sNo }}</span>
                    <span v-if="column.field === 'date'">{{ formatDate(row.date) }}</span>
                    <span v-if="column.field === 'status'">
                        <span :style="{ color: getStatusProperties(row.status).color }">
                            {{ getStatusProperties(row.status).label }}
                        </span>
                    </span>
                    <span v-if="column.field === 'action'" class="d-flex align-items-center" style="width: 70px;">
                        <i class="bi bi-pencil-fill me-3 rovlin-prod-icons_info rovlin-prod-cursor-pointer"
                            aria-hidden="true" @click="editTicket(row)"></i>
                        <i class="bi bi-trash-fill danger rovlin-prod-icons_info ms-2 rovlin-prod-cursor-pointer"
                            aria-hidden="true" style="color: red;" @click="deleteTicket(row.id)"></i>
                    </span>
                </template>
            </VueGoodTable>
        </div>
    </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table-next";
import SweetAlert from "../../../Services/sweetAlerts/SweetAlerts";
import Support from "./Support.vue";
import apiServices from "../../../Services/ApiService/apiService";
import StatusModal from "./statusModal.vue";
import { getStatusProperties } from "../../../Services/utils/statusUtils";

export default {
    name: "SupportHistoryComponent",
    components: {
        VueGoodTable,
        Support,
        StatusModal,
    },
    data() {
        return {
            columns: [
                { label: "S No.", field: "sNo" },
                { label: "Id", field: "id" },
                { label: "Date", field: "date" },
                { label: "Subject", field: "subject" },
                { label: "Description", field: "description" },
                { label: "Status", field: "status" },
                { label: "Action", field: "action" },
            ],
            supportHistoryData: [],
            filteredData: [],
            currentPage: 1,
            pageSize: 10,
            totalRecords: 0,
            isSupportModalOpen: false,
            startDate: null,
            endDate: null,
            isFilterApplied: false,
            today: new Date().toISOString().split("T")[0],
            isStatusModalOpen: false,
            selectedTicket: null,
            selectedStatus: null,
        };
    },
    watch: {
        startDate(newStartDate) {
            if (newStartDate && this.endDate) {
                this.filterByDate();
            }
        },
        endDate(newEndDate) {
            if (newEndDate && this.startDate) {
                this.filterByDate();
            }
        },
    },
    methods: {
        getStatusProperties,
        fetchSupportHistory() {
            this.$store.state.isLoading = true;
            apiServices
                .getSupportHistory(this.currentPage, this.pageSize)
                .then((response) => {
                    const data = response.data;

                    if (data && typeof data === "object") {
                        this.totalRecords = Object.keys(data).length;
                        const startIndex = (this.currentPage - 1) * this.pageSize;

                        this.supportHistoryData = Object.values(data)
                            .slice(startIndex, startIndex + this.pageSize)
                            .map((item, index) => ({
                                ...item,
                                sNo: startIndex + index + 1,
                            }));

                        this.filteredData = this.supportHistoryData;
                    } else {
                        console.error("Unexpected response structure:", response);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching support history:", error);
                })
                .finally(() => {
                    this.$store.state.isLoading = false;
                });
        },
        filterByDate() {
            if (!this.startDate || !this.endDate) {
                this.$store.state.toastMessage = "Please select both start and end dates";
                return;
            }

            if (new Date(this.startDate) > new Date(this.endDate)) {
                this.$store.state.toastMessage = "Start date cannot be after the end date";
                return;
            }

            this.filteredData = this.supportHistoryData.filter((item) => {
                const itemDate = new Date(item.date);
                return itemDate >= new Date(this.startDate) && itemDate <= new Date(this.endDate);
            });

            if (this.filteredData.length === 0) {
                this.$store.state.toastMessage = "No records found for the selected date range";
            }

            this.isFilterApplied = true;
        },
        clearFilter() {
            this.filteredData = this.supportHistoryData;
            this.startDate = null;
            this.endDate = null;
            this.isFilterApplied = false;
        },
        onPageChange(params) {
            this.currentPage = params.currentPage;
            this.fetchSupportHistory();
        },
        onPerPageChange(params) {
            this.pageSize = params.currentPerPage;
            this.currentPage = 1;
            this.fetchSupportHistory();
        },
        openSupportModal() {
            this.isSupportModalOpen = true;
        },
        closeSupportModal() {
            this.isSupportModalOpen = false;
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString();
        },

        deleteTicket(id) {
            SweetAlert.Confirmation({
                title: "Are you sure you want to delete this ticket?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        apiServices
                            .deleteSupport(id)
                            .then(() => {
                                this.$store.state.toastMessage = {
                                    text: "Ticket deleted successfully!",
                                    type: "success",
                                };
                                this.fetchSupportHistory();
                            })
                            .catch((error) => {
                                console.error("Error deleting ticket:", error);
                                this.$store.state.toastMessage = {
                                    text: "Failed to delete ticket.",
                                    type: "error",
                                };
                            });
                    }
                });
        },
        editTicket(ticket) {
            if (!ticket || !ticket.id) {
                this.$store.state.toastMessage = "Invalid ticket data.";
                return;
            }
            this.selectedTicket = ticket;
            this.isStatusModalOpen = true;
        },
        closeStatusModal() {
            this.isStatusModalOpen = false;
            this.selectedTicket = null;
        },
        handleTicketUpdate(updatedData) {
            const dataToSend = {
                status: updatedData.status,
                supportId: updatedData.id,
            };
            apiServices
                .updateSupportStatus(dataToSend)
                .then(() => {
                    this.$store.state.toastMessage = {
                        text: "Ticket status updated successfully!",
                        type: "success",
                    };
                    this.fetchSupportHistory();
                    this.closeStatusModal();
                })
                .catch((error) => {
                    console.error("Error updating ticket status:", error);
                    this.$store.state.toastMessage = {
                        text: "Failed to update ticket status.",
                        type: "error",
                    };
                });
            this.isStatusModalOpen = false;
        },
        handleError(message) {
            this.$store.state.toastMessage = {
                text: message,
                type: "warning",
            };
        },
    },
    computed: {
        paginationFooterText() {
            const startRow = (this.currentPage - 1) * this.pageSize + 1;
            const endRow = Math.min(this.currentPage * this.pageSize, this.totalRecords);
            return `${startRow}-${endRow} of ${this.totalRecords}`;
        },
    },
    created() {
        this.fetchSupportHistory();
    },
};
</script>

<style scoped>
.btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd;
}

.btn-outline-primary:hover {
    background-color: #0d6efd;
    color: white;
}

.btn-cancel{
    color: red;
    border: none;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    margin-top: 10%;
}

::v-deep .vgt-responsive {
    padding: 0rem 1rem !important;
    background-color: white;
}

::v-deep table.vgt-table {
    border: none;
}

::v-deep .vgt-table.bordered th {
    border: none;
}

::v-deep .vgt-table th {
    border-bottom: 1px solid #ddd !important;
    /* border-top: 1px solid #ddd !important; */
}

::v-deep .vgt-table.bordered td {
    border: none;
}

::v-deep .vgt-table td {
    border-bottom: 1px solid #ddd !important;
    padding: 0.5em 1em 0.5em 0.5em !important;
}

::v-deep .vgt-wrap__footer {
    border: none !important;
    background: #fff !important;

}

::v-deep .vgt-table thead th {
    background: #fff !important;
}

::v-deep .vgt-left-align {
    max-width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
</style>
