import { statusesConfig } from "../../../src/components/pages/Support/statusesConfig";

/**
 * Fetches the status properties dynamically.
 * @param {string} status
 * @returns {object}
 */
export function getStatusProperties(status) {
    return (
        statusesConfig[status] || {
            color: "#000",
            label: status,
        }
    );
}
