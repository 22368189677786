<template>
    <nav class="navbar position-sticky top-0">
        <button class="navbar-toggler me-2 d-block d-md-none" @click="changeSidebar()"
            :class="[{ 'd-sm-block': showSidebar }]" style="padding: 1px 2px" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i class="bi bi-list custom-color text-white"></i>
        </button>
        <img alt="Rovlin" src="@/assets/logo-light.png" class="navbar-logo ms-2" />
        <span class="spacer"></span>
        <div class="d-flex align-items-center me-4" v-if="this.$store.state.balance" style="cursor: pointer;">
            <div class="text-white fw-light me-2" @click="navigateToBilling">
                Credit:
            </div>
            <h4 class="mb-auto"><span class=" rounded-pill badge  ms-1" :class="[{ 'bg-success': getBalanceColor() == 'green' }, { 'bg-warning': getBalanceColor() == 'yellow' }, { 'bg-danger': getBalanceColor() == 'red' }
            ]" @click="navigateToBilling">
                    <!-- {{ $store.state.balance }} -->
                    {{ formattedBalance }}
                </span>
            </h4>
        </div>
        <div v-if="isDev" style="margin-right: 12px;">
            <SearchBar />

        </div>
        <div class="helpicon">
            <router-link to="/help">
                <Tooltip icon="bi-question-circle-fill text-white me-2" content="📜 Help" placement="bottom"
                    :newStyle="{ fontSize: '19px' }" />
            </router-link>
        </div>
        <div class="dropdown">
            <div class=" btn rounded-4 border-0 d-flex align-items-center" @click="changeShowProfile()" id="userMenu">
                <AvatarImage :size="31" :username="user?.attributes?.email" />
                <div class="ms-2 d-sm-block d-md-block d-none d-sm-none" style="color:#b9b9b9;">{{
                    user?.attributes?.email
                }}
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import SearchBar from './SearchBar.vue';
import AvatarImage from "./AvatarImage.vue";
import { Auth } from "aws-amplify";
import Tooltip from "@/components/shared/Popper/Tooltip.vue";
import apiService from "@/Services/ApiService/apiService";
export default {
    name: "TopNavbar",
    props: ['changeSidebar', 'showSidebar'],
    components: {
        AvatarImage,
        Tooltip,
        SearchBar
    },

    data() {
        return {
            user: {},
            planOffer: null,

        };
    },
    methods: {


        getBalanceColor() {
            if (this.$store.state.balance) {
                const range = this.planOffer ? this.$store.state.balance / this.planOffer * 100 : 100;
                if (range >= 40) return 'green';
                if (range < 40 && range > 10) return 'yellow';
                if (range <= 10) return 'red';
            }
            return 'green';
        },
        signOut() {
            Auth.signOut().then(() => {
                window.location.href = "https://rovlin.com";
            });
        },
        changeShowProfile() {
            this.$store.state.showProfile = !this.$store.state.showProfile
        },
        getBalanceUser() {
            apiService.getBalanceUser()
                .then((res) => {
                    this.$store.state.balance = res.data.balance
                    console.log(this.availBalance);
                })
        },
        getCurrentPlan() {
            apiService.getCurrentPlan().then(res => {
                this.planOffer = res.data.emails_offer
                console.log(" => plan info : ", this.planOffer);
            })
        },
        navigateToBilling() {
            this.$router.push('/billing');
        },
    },
    created() {

        this.getBalanceUser()
        Auth.currentUserInfo().then((user) => {
            this.user = user;
        });
        this.getCurrentPlan();
    },
    computed: {
         isDev() {
            return process.env.VUE_APP_Environment === "dev";
        },
        formattedBalance() {
            if (this.$store.state.balance != null) {
                return new Intl.NumberFormat('en-US').format(this.$store.state.balance);
            }
            return "0";
        }
    }

};
</script>

<style scoped>
.navbar {
    background-color: #0c244a;
    padding: 10px;

}

.navbar-logo {
    width: 105px;
}

span.spacer {
    flex: 1 1 auto;
}

.custom-color {
    color: white;

}

.custom-color .bi,
.custom-color .fas {
    color: white;

}

.credit {
    font-family: "Arial", sans-serif;
    font-weight: bold;
}


.search-bar {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 350px;
    left: 50px;



}

.form-control {
    border-color: #e9edf6 !important;
    color: #333335 !important;
    background-color: #ffffff !important;
    font-size: 0.875rem;
    font-weight: 500 !important;
    line-height: 1.6;
    border-radius: 0.9rem;
    padding-left: 30px !important;

}

@media(max-width: 400px) {
    .fw-light {
        display: none !important;
    }
}
</style>
